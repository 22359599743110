<template>
  <el-dialog
    id="alarm-dialog"
    class="padding-top: 10px;"
    width="50%"
    :title="title"
    :visible.sync="dialogVisible"
  >
    <div slot="title" class="dialog-title text-lg">
      <AppIcon name="alert" size="l" class="pr-2" /> {{ title }}
    </div>

    <el-input
      placeholder="Search Alarm"
      v-model="search"
      class="input-with-select py-2"
    >
      <el-select v-model="severity" slot="prepend" placeholder="Severity">
        <el-option label="CRITICAL" value="CRITICAL"></el-option>
        <el-option label="WARNING" value="WARNING"></el-option>
        <el-option label="INFORMATIONAL" value="INFORMATIONAL"></el-option>
      </el-select>
    </el-input>
    <div v-loading="loadingState">
      <div
        class="w-full bg-white rounded-lg mb-2 border bottom text-sm m-auto overflow-y-scroll"
        style="max-height: 400px"
        v-if="fetchData().length !== 0"
      >
        <div
          class="flex h-full p-2 rounded list-alarm ..."
          v-for="(item, index) in fetchData()"
        >
          <div class="p-2">
            <div
              class="rounded h-4 w-4"
              :class="getAlertDisplay(item.Severity)"
            />
          </div>
          <div class="py-0 px-2 flex flex-col w-full">
            <div>{{ item.Description }}</div>
            <div class="text-xs">
              <i class="el-icon-date" />
              {{ readableDate(item.ActiveTime) }}
            </div>
          </div>

          <div class="h-full">
            <!-- <el-button -->
            <!--   type="warning" -->
            <!--   size="small" -->
            <!--   :icon="item.button.icon" -->
            <!--   :disabled="item.button.disabled || alarmsData.length === 0" -->
            <!--   @click="acknowledge(item)" -->
            <!-- > -->
            <!--   {{ item.button.text }} -->
            <!-- </el-button> -->
          </div>
        </div>
      </div>

      <div
        class="flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 p-4 bg-white rounded-lg mb-2 border bottom text-sm m-auto..."
        v-else
      >
        <i class="el-icon-info" /> Currently the alarm data is empty...
      </div>

      <div
        class="flex w-full justify-between bg-gray-300 rounded-lg space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 p-4 border bottom text-sm m-auto"
      >
        <div class="flex w-full space-x-0 space-y-2 lg:space-x-2 lg:space-y-0">
          <div
            class="flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 justify-between ..."
          >
            <div class="rounded bg-red-500 text-white h-4 w-4" />
            <div>CRITICAL</div>
          </div>
          <div
            class="flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 justify-between ..."
          >
            <div class="rounded bg-yellow-500 text-white h-4 w-4" />
            <div>WARNING</div>
          </div>
          <div
            class="flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 justify-between ..."
          >
            <div class="rounded bg-blue-500 text-white h-4 w-4" />
            <div>INFORMATIONAL</div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mixin as VueTimers } from "vue-timers"
// import AppIcon from '@/components/AppIcon'

export default {
  components: {
    // AppIcon
    AppIcon: () => import("@/components/AppIcon")
  },
  props: {
    title: {
      type: String,
      default: "Alarms"
    },
    showDialog: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dialogVisible: false,
      acknowledgeClass: {
        text: "Acknowledge Alarm",
        icon: "el-icon-check",
        disabled: false
      },
      search: "",
      severity: "",
      alarmsData: [],
      loadingState: false
    }
  },
  methods: {
    fetchData() {
      var _this = this
      var search = this.search
      var severity = this.severity

      return this.alarmsData
        .map(function (item) {
          item.button = {
            text: "Acknowledge Alarm",
            icon: "el-icon-check",
            disabled: false
          }
          return item
        })
        .filter(function (item) {
          if (search !== "" && severity !== "") {
            return (
              (_this.searchWord(search, item.Severity) ||
                _this.searchWord(search, item.Description) ||
                _this.searchWord(search, item.ActiveTime) ||
                _this.searchWord(
                  search,
                  _this.readableDate(item.ActiveTime)
                )) &&
              item.Severity.includes(severity)
            )
          } else if (search) {
            return (
              _this.searchWord(search, item.Severity) ||
              _this.searchWord(search, item.Description) ||
              _this.searchWord(search, item.ActiveTime) ||
              _this.searchWord(search, _this.readableDate(item.ActiveTime))
            )
          } else if (severity) {
            return _this.searchWord(severity, item.Severity)
          } else {
            return true
          }
        })
    },
    readableDate(stringDate) {
      let newDate = new Date(stringDate)
      return `${newDate.toDateString()} ${newDate.toTimeString()}`
    },
    getAlertDisplay(severity) {
      if (severity === "CRITICAL") {
        return "bg-red-600 text-white"
      } else if (severity === "WARNING") {
        return "bg-yellow-500"
      } else {
        return "bg-green-500 text-white"
      }
    },
    searchWord(search, subject) {
      return subject.toLowerCase().indexOf(search.toLowerCase()) !== -1
    },

    async getAlarmsNotification() {
      try {
        this.loadingState = true
        const res = await this.$axios.get(
          this.$appInfo.api.alarms.getCriticalActiveAlarmsBySite +
            `?serverCode=KVDC`
        )

        var _this = this
        this.alarmsData = res.data.ListAlarm
        this.loadingState = false
      } catch (e) {
        console.log("failed to fetch alarms notification", e)
      }
    },
    async acknowledge(item) {
      try {
        // start acknowledge process
        item.button = {
          text: "Please wait ...",
          icon: "el-icon-loading",
          disabled: true
        }

        this.$notify.info({
          title: `Aknowledge Alarm`,
          message: `Please wait ...`,
          type: "success"
        })

        const res = await this.$axios
          .put(this.$appInfo.api.alarms.acknowledgeAlarm, {
            IdAlarm: item.Id
          })
          .then(() => {
            this.$notify({
              title: "Aknowledge Alarm",
              message: `Data has been updated`,
              type: "success",
              duration: 10000
            })

            // refresh alarm data
            this.getAlarmsNotification()

            // end acknowledge process
            item.button = this.acknowledgeClass
          })
          .catch((error) => {
            var response = error.response
            var message = "System Error. Please contact the administrator"

            if (response.status !== 500) {
              message = response.data.message
            }

            // end acknowledge process
            item.button = this.acknowledgeClass

            this.$notify({
              title: "Aknowledge Alarm",
              message: message,
              type: "error",
              duration: 10000
            })
            throw message
          })
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    dialogVisible: {
      deep: true,
      handler(val) {
        if (!val) {
          this.$emit("onClose", val)
        } else {
          this.getAlarmsNotification()
        }
      }
    },
    showDialog: {
      deep: true,
      handler(val) {
        this.dialogVisible = val
      }
    }
  }
}
</script>

<style>
.list-alarm:nth-child(even) {
  background-color: #f2f2f2;
}
#alarm-dialog .el-dialog__body {
  padding: 10px 20px 20px;
}
</style>
