import appInfo from "@/app-info"
import axios from "axios"
import router from "@/router"

var userId = ""
var passwordRequired = false
var password = ""
var passwordVerified = ""

const emptyData = {
  Base_Url: "",
  Username: "",
  Password: ""
}

var validatePassword = async (rule, value, callback) => {
  // assign password
  password = value

  if (value == "" && passwordRequired) {
    callback(new Error("Password is required"))
  } else {
    callback()
  }
}

var validatePasswordConfirmation = async (rule, value, callback) => {
  // assign password
  passwordVerified = value

  if (value !== password && password !== "") {
    callback(new Error("Password confirmation doesn't match"))
  } else if (value === "" && passwordRequired) {
    callback(new Error("Password Confirmation is required"))
  } else {
    callback()
  }
}

var validationRules = {
  Username: [
    {
      required: true,
      trigger: "change"
    }
  ],
  Password: [
    {
      required: passwordRequired,
      trigger: "change",
      validator: validatePassword
    }
  ],
  PasswordVerified: [
    {
      required: passwordRequired,
      trigger: "change",
      validator: validatePasswordConfirmation
    }
  ],
  Base_Url: [
    {
      required: true,
      message: "Base URL is required",
      trigger: "blur"
    }
  ]
}

export default {
  getEmptyData() {
    return emptyData
  },
  getValidationRule() {
    return validationRules
  },
  setPasswordRequired(isRequired = true) {
    passwordRequired = isRequired
  }
}
