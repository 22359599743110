import Vue from "vue"
import Vuex from "vuex"
import auth from "./auth"
import pdfReport from "./pdfReport"
import tenant from "./tenant"
import map from "./map"

Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    auth,
    tenant,
    pdfReport,
    map
  }
})
