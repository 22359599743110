const List = () => import("@/pages/master/audit-log/List")
// import List from '@/pages/master/audit-log/List'
import layout from "@/layout/Master"

const data = [
  {
    path: "/admin/audit-log",
    name: "auditLog",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Audit Log" },
        { title: "List", active: true }
      ],
      title: "Audit Log",
      module: "Audit Log",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
