const List = () => import("@/pages/master/user/List")
const Form = () => import("@/pages/master/user/Form")
const Profile = () => import("@/pages/master/user/Profile")
// import List from '@/pages/master/user/List'
// import Form from '@/pages/master/user/Form'
// import Profile from '@/pages/master/user/Profile'
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/user",
    name: "user-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "User" },
        { title: "List", active: true }
      ],
      title: "User List",
      module: "User",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/user/create",
    name: "user-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "User" },
        { title: "Create", active: true }
      ],
      title: "User Form - Create",
      module: "User",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/user/view/:id",
    name: "user-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "User" },
        { title: "View", active: true }
      ],
      title: "User View",
      module: "User",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/user/edit/:id",
    name: "user-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "User" },
        { title: "Edit", active: true }
      ],
      title: "User Form - Edit",
      module: "User",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/profile",
    name: "profileView",
    components: {
      content: Profile,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Profile", active: true }
      ],
      title: "Profile",
      module: "Profile",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
