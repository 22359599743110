export default {
  title: "app-info",
  get api() {
    return {
      auth: {
        requestToken: " _Auth /RequestToken",
        request2Authentication: " _Auth /Request2Authentication",
        getAuthUser: " _Auth /GetAuthUser"
      },
      location: {
        getListReportTree: "Location /GetListReportTree",
        getAll: "Location/GetAll",
        getById: "Location /GetById",
        post: "Location /Post",
        put: "Location /Put",
        delete: "Location /Delete"
      },
      report: {
        createAverageReport: "Report/CreateAverageReport",
        createAverageReportByDCEID: "Report/CreateAverageReportByDCEID",
        createAverageReportBySubDeviceName: "Report/CreateAverageReportBySubDeviceName",
        postCreateAverageReportBySubDeviceName: "Report/PostCreateAverageReportBySubDeviceName",
        thisMonthConsumptionAndBill: "Report/ThisMonthConsumptionAndBill",
        getReportDevicedifference: "Report/GetReportDevicedifference"
      },
      reportTree: {
        getCustTreeSensorFiltered: "ReportTree/GetCustTreeSensorFiltered",
        getCustTreeSensorLevel: "ReportTree/GetCustTreeSensorLevel"
      },
      formula: {
        getAll: "Formula/GetAll",
        getAllByAuthorize: "Formula/GetAllByAuthorize",
        getById: "Formula/GetById",
        getAllComponent: "FormulaEditor/GetAllComponent",
        getAllCustomerFormula: "Formula/GetAllCustomerFormula",
        getAllConstantMaster: "Formula/GetAllConstantMaster",
        getAllListTags: "Formula/GetAllListTags",
        getAllByTag: "Formula/GetAllByTag",
        getByListVarName: "Formula/GetByListVarName",
        getFormulaCurrentDataAndComponent: "Formula/GetFormulaCurrentDataAndComponent",
        insertNewFormula: "FormulaEditor/InsertNewFormula",
        insertBulkNewFormula: "FormulaEditor/InsertBulkNewFormula",
        isAvailableVarNameFormula: "Formula/IsAvailableVarNameFormula",
        createMultipleReportFormula: "Formula/CreateMultipleReportFormula",
        getListSensorFromFormula: "Formula/GetListSensorFromFormula",
        decryptFormulaToFormulaCreateModel: "FormulaEditor/DecryptFormulaToFormulaCreateModel",
        updatingBulkFormula: "FormulaEditor/UpdatingBulkFormula",
        post: "Formula/Post",
        put: "Formula/Put",
        delete: "Formula/Delete"
      },
      manualConfig: {
        getConfigByConfigUnit: "ManualConfig/GetConfigByConfigUnit",
        getListServerLocation: "ManualConfig/GetListServerLocation",
        optionsEnumDataFormDashboard: "ManualConfig/OptionsEnumDataFormDashboard",
        optionsEnumVariableType: "ManualConfig/OptionsEnumVariableType",
        optionsEnumProcessForm: "ManualConfig/OptionsEnumProcessForm",
        getAll: "ManualConfig/GetAll",
        getById: "ManualConfig/GetById",
        post: "ManualConfig/Post",
        put: "ManualConfig/Put",
        delete: "ManualConfig/Delete"
      },
      configDce: {
        getAll: "MasterConfigDCE/GetAll",
        put: "MasterConfigDCE/Put",
        getById: "MasterConfigDCE/GetById"
      },
      configExtSystem: {
        getAll: 'MasterConfigDCE/GetAll',
        put: 'MasterConfigDCE/Put',
        getById: 'MasterConfigDCE/GetById',
        GetExtDCIMConfig: 'MasterConfigDCE/GetExtDCIMConfig'
      },
      customer: {
        getListCustomerAndCheckLogo: "Customer/GetListCustomerAndCheckLogo",
        getListCustomerByServerCode: "Customer/GetListCustomerByServerCode",
        getAllByDistinct: "Customer/GetAllbyDistinct",
        getAllByServerCode: "Customer/GetAllByServerCodes",
        getListServerLocationCustomerByCustomerName: "Customer/GetListServerLocationCustomerByCustomerName",
        getListCustomerByMultipleServerCode: "Customer/GetListCustomerByMultipleServerCode",
        getMiniLogohotoBase64: "Customer/GetMiniLogohotoBase64",
        insertLogo: "Customer/InsertLogo",
        insertLogoBase64: "Customer/InsertLogoBase64",
        getLogoBase64: "Customer/GetLogoBase64",
        getCustomerByUsername: "Customer/GetCustomerByUsername",
        getCustomerDropdown: "Customer/DataCustomerDropdown",
        getLocationDce: "Customer/GetLocationDCE",
        treeCustomerUser: "Customer/TreeCustomerUser",
        createUpdateUserCustomers: "Customer/CreateUpdateUserCustomers",
        deleteUserCustomers: "Customer/DeleteUserCustomers",
        getAll: "Customer/GetAll",
        getById: "Customer/GetById",
        post: "Customer/Post",
        put: "Customer/Put",
        delete: "Customer/Delete"
      },
      treeGroupDevice: {
        getCustTreeSensorLevel: "TreeGroupDevice/GetCustTreeSensorLevel",
        getListSensorByDevice: "TreeGroupDevice/GetListSensorByDevice",
        getCustTreeDeviceLevel: "TreeGroupDevice/GetCustTreeDeviceLevel",
        getCustTreeGroupDeviceLevel: "TreeGroupDevice/GetCustTreeGroupDeviceLevel",
        getCustTreeGroupDeviceLevelWithFormula: "TreeGroupDevice/GetCustTreeGroupDeviceLevelWithFormula",
        getListSensorByDevicePost: "TreeGroupDevice/GetListSensorByDevicePost"
      },
      dashboard: {
        getWidgetSetting: "Dashboard/GetWidgetSetting",
        getDashboardWidgetData: "Dashboard/GetDashboardWidgetData",
        getListAvailableWidget: "Dashboard/GetListAvailableWidget",
        changeWidgetSetting: "Dashboard/ChangeWidgetSetting",

        custom1TMONE: "Dashboard/DashboardCustom1TMONE"
      },
      user: {
        getAllUserRp: "User/GetAllUserRp",
        createUserRoles: "User/CreateUserRoles",
        getAllUserRpByUsername: "User/GetAllUserRpByUsername",
        isAvailableUsername: "User/IsAvailableUsername",
        getAll: "User/GetAll",
        getAllByAuthorize: "User/GetAllByAuthorize",
        getById: "User/GetById",
        post: "User/Post",
        put: "User/Put",
        delete: "User/Delete"
      },
      docs: {
        getAll: "MasterDocuments/GetAll",
        uploadImage: "MasterDocuments/UploadFile",
        getById: "MasterDocuments/GetById",
        post: "MasterDocuments/Post",
        put: "MasterDocuments/Put",
        delete: "MasterDocuments/Delete"
      },
      tickets: {
        getAll: "CustomerTicket/GetAll",
        getById: "CustomerTicket/GetById",
        post: "CustomerTicket/Post",
        put: "CustomerTicket/Put",
        delete: "CustomerTicket/Delete"
      },
      role: {
        getById: "Roles/GetById",
        getAll: "Roles/GetAll",
        post: "Roles/Post",
        put: "Roles/Put",
        delete: "Roles/Delete"
      },
      permission: {
        getAll: "Permission/GetAll",
        getById: "Permission/GetById",
        post: "Permission/Post",
        put: "Permission/Put",
        delete: "Permission/Delete"
      },
      tag: {
        listMasterTagByCustomerAndGeneral: "MasterTag/ListMasterTagByCustomerAndGeneral",
        listStringTagsAuthorize: "MasterTag/ListStringTagsAuthorize",
        listStringTagsAuthorizeReport: "MasterTag/ListStringTagsAuthorizeReport",
        postUpdateData: "MasterTag/PostUpdateData",
        postTagName: "MasterTag/PostTagName",
        getAll: "MasterTag/GetAll",
        getById: "MasterTag/GetById",
        post: "MasterTag/Post",
        put: "MasterTag/Put",
        delete: "MasterTag/Delete"
      },
      widget: {
        post: "MasterWidget/Post",
        getAll: "MasterWidget/GetAll",
        getById: "MasterWidget/GetById",
        put: "MasterWidget/Put",
        delete: "MasterWidget/Delete",
        isAvailableWidgetName: "MasterWidget/IsAvailableWidgetName"
      },
      chart: {
        getAll: "MasterChart/GetAll",
        getById: "MasterChart/GetById",
        post: "MasterChart/Post",
        put: "MasterChart/Put",
        delete: "MasterChart/Delete"
      },
      mailList: {
        sendMail: "MailList/SendMail",
        createSchedulingReport: "MailList/CreateSchedulingReport",
        sendReportToEmail: "MailList/SendReportToEmail",
        sendReportAndPDFToEmail: "MailList/SendReportAndPDFToEmail",
        uploadReportPDF: "MailList/UploadReportPDF",
        uploadReportPDFBase64: "MailList/UploadReportPDFBase64",
        getById: "MailList/GetById",
        getAllAuthorize: "MailList/GetAllAuthorize",
        put: "MailList/Put",
        callAutomationReport: "MailList/CallAutomationReport",
        getAll: "MailList/GetAll",
        post: "MailList/Post",
        delete: "MailList/Delete"
      },
      reportPdf: {
        generateExcelReportTest: "ReportPDFetc/GenerateExcelReportTest",
        downloadFileReport: "ReportPDFetc/DownloadFileReport"
      },
      alarms: {
        getAllActiveAlarms: "Alarms/GetALLActiveAlarms",
        getCriticalActiveAlarmsBySite: "Alarms/GetCriticalActiveAlarmsBySite",
        countCriticalAlarm: "Alarms/CountCriticalAlarm",
        getActiveAlarmsByType: "Alarms/GetActiveAlarmsByType",
        getActiveAlarmsByTypeAndRoom: "Alarms/GetActiveAlarmsByTypeAndRoom",
        getHistoricalAlarmdata: "Alarms/GetHistoricalAlarmdata",
        getDashboardDataFormulaAlarm: "Alarms/GetDashboardDataFormulaAlarm",
        getHistoricalAlarmForDummy: "Alarms/GetHistoricalAlarmForDummy",
        acknowledgeAlarm: "Alarms/AcknowledgeAlarm",
        acknowledgeBulkAlarm: "Alarms/AcknowledgeBulkAlarm"
      },
      userlog: {
        getAll: "AuditLog/GetAll",
        getFilter: "AuditLog/GetFilter"
      },
      version: {
        getAll: "Version/GetAll",
        getById: "Version/GetById",
        updateAndSyncDatabase: "Version/UpdateAndSyncDatabase",
        backup: "Version/Backup",
        restore: "Version/Restore",
        existingBackup: "Version/ExistingBackup",
        uploadBackup: "Version/UploadBackup",
        deleteBackup: "Version/DeleteBackup",
        downloadBackup: "Version/DownloadBackup",
        post: "Version/Post",
        put: "Version/Put",
        delete: "Version/Delete"
      }
    }
  }
}
