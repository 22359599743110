import Vue from "vue"
import appsettings from "../config.json"
import VueCookies from "vue-cookies"
Vue.use(VueCookies)
import axios from "axios"
import router from "@/router"

axios.defaults.baseURL = appsettings.baseUrl

export default {
  state: {
    token: Vue.$cookies.get("__auth") || null,
    validToken: false,
    isTenant: false,
    isAdmin: false,
    isSuperAdmin: false,
    isCustomerAdmin: false,
    isCustomer: false,
    isStaff: false,
    userData: {},
    permissions: [],
    roles: [],
    dataCenterEnumRole: ["IPDC Admin", "KVDC Admin", "Brickfields Admin"],
    menu: [],
    status: "",
    message: "",
    currentRoute: {}
  },
  getters: {
    loggedIn(state) {
      return state.token !== null
    },
    validToken(state) {
      return state.validToken
    },
    isTenant(state) {
      return state.isTenant
    },
    isCustomerAdmin(state) {
      return state.isCustomerAdmin
    },
    isAdmin(state) {
      return state.isAdmin
    },
    isSuperAdmin(state) {
      return state.isSuperAdmin
    },
    isCustomer(state) {
      return state.isCustomer
    },
    userData(state) {
      return state.userData
    },
    authStatus(state) {
      return state.status
    },
    currentApprover(state) {
      return state.userData.RoleName
    },
    message(state) {
      return state.message
    },
    authorized(state) {
      var meta = state.currentRoute.meta

      return (
        state.token !== null &&
        state.userData.ListPermission.filter(function (x) {
          var fullPath = state.currentRoute.fullPath

          if (fullPath == "/") {
            return x.Path === fullPath
          } else {
            return x.PermissionName === meta.module
          }
        }).length > 0
      )
    }
  },
  mutations: {
    auth_success(state) {
      state.status = "success"
    },
    auth_error(state) {
      state.status = "error"
    },
    auth_request(state) {
      state.status = "loading"
    },
    retrieveToken(state, token) {
      state.token = token
    },
    validToken(state, isValid) {
      state.validToken = isValid
    },
    setUserData(state, user) {
      state.userData = user.data
      state.roles = user.data.RolesName
      state.menu = user.data.ListPermission
      state.permissions = user.data.PermissionsName

      // set is admin
      state.isAdmin =
        user.data.RolesName.includes("Super Admin") ||
        user.data.RolesName.includes("TM Administrator") ||
        user.data.RolesName.includes("Customer Admin")

      // set is tm admin
      state.isSuperAdmin =
        user.data.RolesName.includes("Super Admin") ||
        user.data.RolesName.includes("TM Administrator")

      // set is customer
      state.isCustomer =
        user.data.RolesName.includes("Customer Admin") ||
        user.data.RolesName.includes("Customer Staff")

      // set is staff
      state.isStaff =
        user.data.RolesName.includes("Staff") ||
        user.data.RolesName.includes("Customer Staff")

      // set is tenant admin
      state.isCustomerAdmin = user.data.RolesName.includes("Customer Admin")
    },
    message(state, msg) {
      state.message = msg
    },
    destroyToken(state) {
      state.token = null
      state.isTenant = false
      state.isAdmin = false
    },
    setCurrentRoute(state, route) {
      state.currentRoute = route
    }
  },
  actions: {
    deleteToken(context) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token
      if (context.getters.loggedIn) {
        try {
          localStorage.removeItem("access_token")
          localStorage.removeItem("user")
          localStorage.removeItem("userAuth")
          context.commit("destroyToken")
          axios.defaults.headers.common["Authorization"] = "Bearer "
          Vue.$cookies.remove("__auth")
        } catch (e) {
          console.log("failed to logout", e)
        }
      }
    },
    async getLoginKey(context, credentials) {
      try {
        context.commit("auth_request")
        context.commit("message", "")

        var res = await axios.post("/_Auth/Request2Authentication", credentials)

        context.commit("auth_success")
        context.commit("message", res.data.message)
      } catch (e) {
        context.commit("auth_error")
        context.commit("message", e.response.data.message)
        localStorage.removeItem("access_token")
      }
    },
    async getToken(context, credentials) {
      try {
        context.commit("auth_request")
        context.commit("message", "")
        var res = await axios.post("/_Auth/RequestToken", credentials)

        const token = res.data.Token
        const user = {
          username: res.data.Username,
          fullname: res.data.Fullname,
          email: res.data.Email
        }

        // store login key
        localStorage.setItem("access_token", token)
        Vue.$cookies.set("__auth", `Bearer ${token}`, "1d")
        localStorage.setItem("user", JSON.stringify(user))
        axios.defaults.headers.common["Authorization"] = "Bearer " + token
        context.commit("retrieveToken", token)

        if (res.data.Role === "Administrator") {
          router.push({ name: "Home" })
        } else {
          router.push({ name: "Tenant Monitoring" })
        }
      } catch (e) {
        context.commit("auth_error")
        context.commit("message", e.response.data.message)
        localStorage.removeItem("access_token")
      }
    },
    async getUserProfile(context) {
      try {
        const res = await axios.get("/_Auth/GetAuthUser")
        context.commit("setUserData", res)
      } catch (e) {
        console.log("failed to fetch user data", e)
      }
    },
    async validateToken(context) {
      try {
        const res = await axios.get("/_Auth/IsKeyValid")
        context.commit("validToken", res.data)
      } catch (e) {
        context.commit("validToken", false)
        console.log("failed to validToken", e)
      }
    }
  }
}
