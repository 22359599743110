<template>
  <div class="flex flex-col">
    <h5 class="text-sm lg:text-xl font-bold text-gray-700">
      Logged in user: {{ user.Username }} ({{ userRoles }})
    </h5>
    <h5 class="text-sm :text-xl font-bold text-gray-700">
      Timestamp: {{ date }} (Malaysia Time)
    </h5>
  </div>
</template>

<script>
export default {
  computed: {
    userRoles() {
      return this.$store.state.auth?.userData?.RolesName[0]
    },
    user() {
      return this.$store.state.auth.userData
    },
    date() {
      let date = new Date()
      return date.toLocaleString("en-US", { timeZone: "Asia/Kuala_Lumpur" })
    }
  }
}
</script>
