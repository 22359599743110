import Vue from "vue"
import lang from "element-ui/lib/locale/lang/en"
import locale from "element-ui/lib/locale"

import "./assets/css/tailwind.css"
import "./assets/css/progressloading.css"
import {
  Button,
  Input,
  Dialog,
  Menu,
  Select,
  Row,
  Option,
  Icon,
  Loading,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  FormItem,
  Form,
  OptionGroup,
  Tag,
  Tooltip,
  Col,
  Card,
  Cascader,
  DatePicker,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Switch,
  Checkbox,
  CheckboxGroup,
  Tree,
  MessageBox,
  Message,
  Notification,
  Tabs,
  TabPane
} from "element-ui"

import App from "./App.vue"
import router from "./router"
import axios from "axios"
import store from "./store"
import * as svgicon from "vue-svgicon"
import appInfo from "./app-info"
import "devextreme/dist/css/dx.common.css"
import "devextreme/dist/css/dx.light.css"
// import "devextreme/dist/css/dx.material.blue.light.compact.css"
import Inputmask from "inputmask"
import appsettings from "./config.json"
import VueTimers from "vue-timers"
import moment from "moment"

const elementComponent = [
  Button,
  Input,
  Dialog,
  Menu,
  Select,
  Row,
  Option,
  Icon,
  Loading,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  FormItem,
  Form,
  OptionGroup,
  Tag,
  Tooltip,
  Col,
  Card,
  Cascader,
  DatePicker,
  MenuItem,
  MenuItemGroup,
  Submenu,
  Switch,
  Checkbox,
  CheckboxGroup,
  Tree,
  Tabs,
  TabPane
  // MessageBox,
  // Message,
  // Notification
]

// app info
Vue.prototype.$appInfo = appInfo
Vue.prototype.$moment = moment
Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message

// for docs file only
Vue.prototype.$baseURL = appsettings.baseUrl.split("/api").join("")
// import the styles

Vue.use(axios)
elementComponent.forEach((comp) => Vue.use(comp))
locale.use(lang)

Vue.config.productionTip = false

Vue.prototype.$axios = axios

// We install `vue-svgicon` as plugin
// and configure it to prefix all CSS
// classes with `AppIcon-`.
Vue.use(svgicon, {
  classPrefix: "AppIcon-"
})

// loading screen
Vue.prototype.loading = function () {
  return this.$loading({
    lock: true,
    text: "Loading",
    spinner: "el-icon-loading"
  })
}

// Inputmask
Vue.directive("input-mask", {
  bind: function (el, binding) {
    var inputs = el.getElementsByTagName("INPUT")
    var input = inputs[0]
    if (inputs.length > 1) {
      input = inputs[inputs.length - 1]
    }
    new Inputmask(binding.value).mask(input)
  }
})

axios.interceptors.request.use(
  (config) => {
    // let token = localStorage.getItem('access_token')
    let token = Vue.$cookies.get("__auth")

    if (token) {
      config.headers["Authorization"] = token
    }
    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    if (error.response.status === 401) {
      store.dispatch("deleteToken")
      router.push("/login")
    }
    return Promise.reject(error)
  }
)

// Use VueTimers
Vue.use(VueTimers)
Vue.use(Loading.directive)

new Vue({
  router,
  axios,
  store,
  render: (h) => h(App)
}).$mount("#app")
