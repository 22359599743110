import List from "@/pages/config/config-dce-dco/List"
import Form from "@/pages/config/config-dce-dco/Form"
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/config-dce-dco",
    name: "config-dce-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Config DCE & DCO" },
        { title: "List", active: true }
      ],
      title: "Config DCE-DCO List",
      module: "Config DCE",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/config-dce-dco/view/:id",
    name: "config-dce-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Config DCE & DCO" },
        { title: "View", active: true }
      ],
      title: "Config DCE & DCO View",
      module: "Config DCE",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/config-dce-dco/edit/:id",
    name: "config-dce-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Config DCE & DCO" },
        { title: "Edit", active: true }
      ],
      title: "Config DCE & DCO Edit",
      module: "Config DCE",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
