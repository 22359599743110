<template>
  <div class="h-screen bg-gray-300">
    <slot />
  </div>
</template>

<script>
export default {
  name: "defaultLayout"
}
</script>
