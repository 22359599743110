<template>
  <svgicon
    :class="{
      [$options.name]: true,
      [`${$options.name}--${size}`]: size
    }"
    :original="original"
    :color="color"
    :name="name"
  >
  </svgicon>
</template>

<script>
export default {
  name: "AppIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String
    },
    size: {
      type: String
    },
    original: {
      type: Boolean
    }
  },
  created() {
    // The `[request]` placeholder is replaced
    // by the filename of the file which is
    // loaded (e.g. `AppIcon-music.js`).
    import(/* webpackChunkName: "AppIcon-[request]" */ `./icons/${this.name}`)
  }
}
</script>

<style lang="scss">
.AppIcon {
  display: inline-block;
  height: 1em;
  color: inherit;
  vertical-align: middle;
  fill: none;
  stroke: currentColor;
}

.AppIcon--fill {
  fill: currentColor;
  stroke: none;
}

.AppIcon--s {
  height: 0.5em;
}

.AppIcon--m {
  height: 1em;
}

.AppIcon--l {
  height: 2em;
}

.AppIcon--larger {
  height: 3rem;
  width: 3rem;
}

.AppIcon--Header {
  height: 3rem;
  @screen xlarge {
    height: 4rem;
  }
  @screen xxlarge {
    height: 5rem;
  }
}
.AppIcon--xl {
  height: 5rem;
  @screen xlarge {
    height: 6rem;
  }
  @screen xxlarge {
    height: 8rem;
  }
}

.AppIcon--home {
  height: 8rem;
  @screen xlarge {
    height: 10rem;
  }
  @screen xxlarge {
    height: 12rem;
  }
}
</style>
