const List = () => import("@/pages/config/sync/List")
// import List from '@/pages/config/sync/List'
import layout from "@/layout/Master"

const data = [
  {
    path: "/config/sync",
    name: "sync-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "sync" },
        { title: "List", active: true }
      ],
      title: "sync List",
      module: "Sync Data",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
