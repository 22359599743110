const List = () => import("@/pages/config/constanta/List")
const Form = () => import("@/pages/config/constanta/Form")
// import List from '@/pages/config/constanta/List'
// import Form from '@/pages/config/constanta/Form'
import layout from "@/layout/Master"

const data = [
  {
    path: "/config/constanta",
    name: "constanta-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Formula Constanta" },
        { title: "List", active: true }
      ],
      title: "Formula Constanta List",
      module: "Formula Constanta",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/constanta/create",
    name: "constanta-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Formula Constanta" },
        { title: "Create", active: true }
      ],
      title: "Formula Constanta Create",
      module: "Formula Constanta",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/constanta/view/:id",
    name: "constanta-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Formula Constanta" },
        { title: "View", active: true }
      ],
      title: "Formula Constanta View",
      module: "Formula Constanta",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/constanta/edit/:id",
    name: "constanta-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Formula Constanta" },
        { title: "Edit", active: true }
      ],
      title: "Formula Constanta Edit",
      module: "Formula Constanta",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
