<template>
  <div>
    <div style="display: flex; justify-content: flex-end; margin-bottom: 10px">
      <div class="w-full" v-if="title">
        <h6 class="pt-2">{{ title }}</h6>
      </div>
      <DxButton
        v-if="options.allowToAdd"
        icon="plus"
        text="Add New"
        type="default"
        @click="
          () => {
            $router.push({ path: customizationPage, query: query })
          }
        "
      />
      <DxButton
        v-if="options.allowToRefetch"
        style="margin-left: 5px"
        icon="refresh"
        type="normal"
        @click="
          () => {
            $parent.fetchData()
          }
        "
      />
      <DxButton
        v-if="options.allowExporting"
        style="margin-left: 5px"
        icon="export"
        type="normal"
        @click="exportToExcel = true"
        @exportToExcelFinish="exportToExcel = false"
      />
      <el-button
        type="primary"
        size="small"
        class="mx-2"
        @click="$emit('next-step')"
        v-if="options.allowToNextStep"
      >
        Next <i class="el-icon-d-arrow-right"></i>
      </el-button>
    </div>
    <DataBinding
      :page-title="title"
      :headers="headers"
      :items="items"
      :options="options"
      :customizationPage="customizationPage"
      :exportToExcel="exportToExcel"
      :printable="printable"
      :selectedIds="selectedIds"
      :table-id="tableId"
      @selection-changed="onSelectionChanged"
      @edit-row="onRowEditing"
      @remove-row="onRowRemoving"
      @download-row="onRowDownloading"
      @restore-row="onRowRestoring"
    />
  </div>
</template>

<script>
import DataBinding from "@/components/dx/DataBinding"
import DxButton from "devextreme-vue/button"

export default {
  props: {
    tableId: {
      type: String,
      default: () => "Id"
    },
    title: {
      type: String,
      default: () => ""
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    customizationPage: {
      type: String,
      default: () => ""
    },
    query: {
      type: Object,
      default: () => {}
    },
    printable: {
      type: Boolean,
      default: () => false
    },
    selectedIds: {
      type: Array
    }
  },
  components: {
    DataBinding,
    DxButton
  },
  data: () => {
    return {
      exportToExcel: false
    }
  },
  methods: {
    onSelectionChanged(selectedRowsData) {
      this.$emit("selection-changed", selectedRowsData)
    },
    onRowEditing(selectedRowsData) {
      this.$emit("edit-row", selectedRowsData)
    },
    onRowRemoving(selectedRowsData) {
      this.$emit("remove-row", selectedRowsData)
    },
    onRowDownloading(selectedRowsData) {
      this.$emit("download-row", selectedRowsData)
    },
    onRowRestoring(selectedRowsData) {
      this.$emit("restore-row", selectedRowsData)
    }
  }
}
</script>
