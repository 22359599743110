// import appsettings from "../config.json"
// import axios from "axios"

export default {
  state: {
    dataCenter: "TM"
  },
  getters: {
    dataCenter(state) {
      return state.dataCenter
    }
  },
  mutations: {
    dataCenterMutation(state, payload) {
      state.dataCenter = payload
    }
  },
  actions: {
    setDataCenter({ commit }, payload) {
      commit("dataCenterMutation", payload)
    }
  }
}
