const List = () => import("@/pages/master/ticketsAdmin/List")
const Form = () => import("@/pages/master/ticketsAdmin/Form")
// import List from '@/pages/master/ticketsAdmin/List'
// import Form from '@/pages/master/ticketsAdmin/Form'
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/admin/tickets",
    name: "admin-tickets-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tickets" },
        { title: "List", active: true }
      ],
      title: "Tickets List",
      module: "Tickets Admin",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/admin/tickets/view/:id",
    name: "admin-tickets-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tickets" },
        { title: "View", active: true }
      ],
      title: "Tickets View",
      module: "Tickets Admin",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/admin/tickets/edit/:id",
    name: "admin-tickets-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tickets" },
        { title: "Edit", active: true }
      ],
      title: "Tickets Edit",
      module: "Tickets Admin",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
