<template>
  <div
    class="background-section overflow-auto min-h-screen"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg.png') + ')',
      backgroundAttachment: 'fixed'
    }"
  >
    <div class="flex-none w-full text-right">
      <img
        src="@/assets/images/logo-schneider-electric.png"
        alt="Schneider Electric Logo"
        title="Schneider Electric Logo"
        class="h-16 inline-block m-3"
      />
    </div>
    <div
      class="flex flex-col ... py-4 max-w-screen-large mx-auto px-2 lg:px-16 xl:px-12"
    >
      <!-- header logo -->
      <!-- <div class="flex-none w-full text-right">
        <img
          src="@/assets/images/logo-schneider-electric.png"
          alt="Schneider Electric Logo"
          title="Schneider Electric Logo"
          class="h-12 inline-block mb-4"
        />
      </div> -->

      <!-- content & menu -->
      <div class="flex-grow w-full px-4 py-2 rounded-lg bg-white shadow-lg">
        <Header />
        <slot />
      </div>
      <!-- footer logo -->
      <div class="flex-none w-full py-8 flex">
        <Footer class="flex-1 ..." />
        <div class="flex-1 ... text-right">
          <img
            src="/images/EcoStruxure_logo.png"
            class="h-10 lg:h-12 inline-block"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Portal/Footer"
import Header from "@/components/Portal/Header"
export default {
  name: "portalLayout",
  components: {
    Header,
    Footer
  }
}
</script>
<style lang="scss">
// @apply bg-gradient-to-r from-indigo-500 to-pink-700;

.max-w-screen-large {
  max-width: 1440px;
}
.grid-custom {
  display: grid;
  grid-template-columns: 80px repeat(4, minmax(200px, 1fr)) 80px;
  grid-template-rows: 80px 1fr 1fr 80px;
}
.background-section {
  height: 100%;
  background-repeat: repeat-x;
  background-color: #f1f2f3;
  background-size: cover;
}

.dx-datagrid,
.dx-datagrid .dx-row > td,
.dx-theme-material-typography {
  font-size: 12px !important;
}
</style>
<style>
#chartjs-tooltip {
  background: rgba(29, 29, 29, 0.9) none repeat scroll 0% 0% !important;
  color: #fff !important;
  border-radius: 8px !important;
  padding: 12px !important;
  z-index: 99;
}
</style>
