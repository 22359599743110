import axios from "axios"
export default {
  state: {
    sensor: []
  },
  getters: {
    sensorData(state) {
      return state.sensor
    }
  },
  mutations: {
    sensorName(state, sensor) {
      state.sensor = sensor
    }
  },
  actions: {
    async addSensor(context, tenant) {
      try {
        this.loading = true
        const res = await axios.get(
          `http://ecocim-backend-tm-one.beit.co.id/api/TreeGroupDevice/GetListSensorByDevice?DeviceDceId=${tenant}`
        )
        context.commit("sensorName", res.data)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  }
}
