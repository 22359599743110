<template>
  <div class="bg-white flex flex-col p-4 rounded" v-loading="loading">
    <h3>Config DCE & DCO Form - {{ $route.params.id ? "Edit" : "Create" }}</h3>
    <hr class="my-4" />
    <div id="form-container" class="pt-2">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="validationRules"
        size="medium"
        label-width="auto"
      >
        <el-form-item label="Base_Url" prop="Base_Url" v-if="mode !== 'edit'">
          <el-input
            v-model="formData.Base_Url"
            placeholder="Base URL"
            clearable
          >
          </el-input>
        </el-form-item>

        <div class="flex flex-col gap-2">
          <el-form-item
            label="Base URL"
            class="w-full"
            prop="Base_Url"
            style="margin-bottom: 35px"
            :required="mode !== 'edit'"
          >
            <el-input
              v-model="formData.Base_Url"
              placeholder="Base URL"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Status"
            class="w-full"
            prop="Status"
            style="margin-bottom: 35px"
            :required="mode !== 'edit'"
          >
            <el-input disabled v-model="formData.Status" clearable></el-input>
          </el-form-item>
          <el-form-item
            label="Username"
            class="w-full"
            prop="Username"
            style="margin-bottom: 35px"
            :required="mode !== 'edit'"
          >
            <el-input
              v-model="formData.Username"
              placeholder="Username"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Password"
            class="w-full"
            prop="Password"
            style="margin-bottom: 35px"
            :required="mode !== 'edit'"
          >
            <el-input
              v-model="formData.Password"
              placeholder="Password"
              clearable
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Confirm Password"
            class="w-full"
            prop="PasswordVerified"
            :required="mode !== 'edit'"
          >
            <el-input
              v-model="formData.PasswordVerified"
              placeholder="Confirm Password"
              clearable
              show-password
            ></el-input>
          </el-form-item>
        </div>

        <hr class="my-4" />
        <div class="w-full text-right">
          <el-button type="primary" @click="submit">Submit</el-button>
          <el-button @click="cancel">Cancel</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import formService from "@/mixins/FormService"
import formHelper from "./form-helper"
// import { DxButton } from 'devextreme-vue'
// import { DxForm, DxSimpleItem, DxLabel } from 'devextreme-vue/form'

let service

export default {
  components: {
    // DxButton,
    // DxForm,
    // DxSimpleItem,
    // DxLabel
  },
  created() {
    formHelper.setPasswordRequired(!this.$route.params.id)
    service = new formService(this, formHelper)
  },
  async mounted() {
    this.loading = true
    this.formData = await service.initialFormData()

    // set mode
    if (this.$route.params.id) {
      this.mode = "edit"
      this.formData.Password = ""
    }
    this.loading = false
  },
  data() {
    return {
      apiGetById: this.$appInfo.api.configDce.getById,
      apiPut: this.$appInfo.api.configDce.put,
      formData: formHelper.getEmptyData(),
      validationRules: formHelper.getValidationRule(),
      roles: [],
      costumers: [],
      mode: "",
      isSubmit: false,
      loading: false
    }
  },
  methods: {
    setDefaultCustomerId() {
      if (this.isCustomer) {
        this.formData.CustomerName[0] = this.user.CustomerName
          ? this.user.CustomerName[0]
          : ""
      }
    },

    async cancel() {
      this.$router.go(-1)
    },
    async submit() {
      this.$refs.elForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true
            this.isSubmit = true

            if (this.mode === "edit" && this.formData.Password === "") {
              //hapus property password jika kosong
              delete this.formData.Password
            }

            await service.submit(this.formData)
            this.$message.success("Data Saved")
            this.loading = false
          } catch (e) {
            this.loading = false
            this.$message.error(e)
            console.log(e)
          }
        } else {
          this.$message.warning("Please fill in the required data")
          this.loading = false
          return false
        }
      })
    },
    async fetchRole() {
      try {
        this.loading = true
        const res = await this.$axios.get(`${this.$appInfo.api.role.getAll}`)

        this.roles = res.data

        // filter customer role
        if (this.isCustomer) {
          this.roles = res.data.filter(
            (x) =>
              x.RoleName === "Customer Staff" || x.RoleName === "Customer Admin"
          )
        }

        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    },
    async fetchCustomer() {
      try {
        this.loading = true
        const res = await this.$axios.get(
          `${this.$appInfo.api.customer.getAll}`
        )
        this.costumers = res.data
        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    }
  },
  computed: {
    isCustomer() {
      return this.$store.state.auth.isCustomer
    },
    isSuperAdmin() {
      return this.$store.state.auth.isSuperAdmin
    },
    user() {
      return this.$store.state.auth.userData
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSubmit) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      )
      if (answer) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  }
}
</script>
