// pdfReport.js

// import dependecies
import store from "@/store"
import FileSaver from "file-saver"
import printJS from "print-js"

// Will be handled by worker-plugin
// const actions = new Worker("../helpers/GeneratePdf.js", { type: "module" })
const actions = new Worker(
  new URL("../helpers/GeneratePdf.js", import.meta.url)
)

const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob) // or however you want to check it

const printPDF = (blob) => {
  try {
    isIE11
      ? window.navigator.msSaveOrOpenBlob(blob, "documents.pdf")
      : printJS(URL.createObjectURL(blob)) // http://printjs.crabbly.com/
  } catch (e) {
    throw e
  }
}

export default {
  state: {
    progressFinished: false,
    printFinished: false,
    downloadFinished: false,
    sendEmailFinished: false,
    base64Source: null,
    blobSource: null,
    fileName: ""
  },
  getters: {
    blobSource(state) {
      return state.blobSource
    },
    base64Source(state) {
      return state.base64Source
    },
    pdfFileName(state) {
      return state.fileName
    },
    progressFinished(state) {
      return state.progressFinished
    },
    downloadFinished(state) {
      return state.downloadFinished
    },
    printFinished(state) {
      return state.printFinished
    },
    sendEmailFinished(state) {
      return state.sendEmailFinished
    }
  },
  mutations: {
    SET_PROGRESS(state, value) {
      state.progressFinished = value
    },
    SET_BLOBSOURCE(state, value) {
      state.blobSource = value
    },
    SET_BLOBSOURCE(state, value) {
      state.blobSource = value
    },
    SET_BASE64SOURCE(state, value) {
      state.base64Source = value
    },
    SET_FILENAME(state, value) {
      state.fileName = value
    },
    SET_SENDEMAIL(state, value) {
      state.sendEmailFinished = true
      state.base64Source = value
    },
    SET_PRINT(state, value) {
      printPDF(value)
      state.printFinished = true
    },
    SET_DOWNLOAD(state, value) {
      FileSaver.saveAs(value, store.getters.pdfFileName)
      state.downloadFinished = true
    },
    RESET_PROGRESS(state) {
      state.progressFinished = false
      state.downloadFinished = false
      state.printFinished = false
      state.sendEmailFinished = false
    }
  },
  actions: {
    async base64PDF({ commit }, pdfSource) {
      actions.postMessage({
        type: "SET_SENDEMAIL",
        payload: JSON.stringify(pdfSource)
      })
    },
    async downloadPdf({ commit }, pdfSource) {
      actions.postMessage({
        type: "SET_DOWNLOAD",
        payload: JSON.stringify(pdfSource)
      })
    },
    async printPdf({ commit }, pdfSource) {
      actions.postMessage({
        type: "SET_PRINT",
        payload: JSON.stringify(pdfSource)
      })
    }
  }
}

// Handle incoming messages as commits!
actions.onmessage = (e) => {
  store.commit(e.data.type, e.data.payload)
}
