<template>
  <div>
    <DxDataGrid
      id="simpleTable"
      ref="simpleTable"
      :key-expr="tableId"
      :data-source="items"
      :show-borders="true"
      :row-alternation-enabled="true"
      :column-min-width="100"
      :allow-column-resizing="true"
      :column-auto-width="options.autoWidth"
      :selected-row-keys="selectedRowKeys"
      @selection-changed="onSelectionChanged"
    >
      <!-- Paging and Pager -->
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="[10, 20, 50]"
        :show-info="true"
      />

      <DxColumnFixing :enabled="true" />

      <!-- Set Columns -->
      <template v-for="(head, index) in headers">
        <DxColumn
          :width="null"
          :data-type="head.type"
          :caption="head.text"
          :data-field="head.value"
          :cell-template="head.cellTemplate"
          :allow-sorting="head.text !== 'Action' && options.allowSorting"
          :allow-filtering="head.text !== 'Action'"
          :allow-fixing="head.allowFixing"
          :visible="head.visible"
          :format="head.format ? head.format : ''"
          :fixed="head.allowFixing"
          fixed-position="right"
          v-if="head.type === 'date'"
        />

        <DxColumn
          :width="null"
          :data-type="head.type"
          :caption="head.text"
          :data-field="head.value"
          :cell-template="head.cellTemplate"
          :allow-sorting="head.text !== 'Action' && options.allowSorting"
          :allow-filtering="head.text !== 'Action'"
          :allow-fixing="head.allowFixing"
          :visible="head.visible"
          :alignment="head.alignment ? head.alignment : 'left'"
          :fixed="head.allowFixing"
          fixed-position="right"
          v-else
        />
      </template>

      <!-- Activate Selection -->
      <DxSelection
        :select-all-mode="'allPages'"
        :show-check-boxes-mode="'onClick'"
        v-if="options.allowMultipleSelect"
        mode="multiple"
      />

      <!-- Activate Filter -->
      <DxFilterRow :visible="!printable" />

      <!-- Editing -->
      <DxEditing
        :allow-adding="options.allowAdding"
        :allow-updating="options.allowEditing"
        :allow-deleting="options.allowDeleting"
        mode="form"
      >
        <DxForm :on-content-ready="validateForm">
          <!-- String Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter((i) => i.type === 'string')"
            :key="'form-field-string-' + index"
            :data-field="head.value"
          >
            <DxLabel :text="head.text" />
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
            <DxPatternRule
              v-if="head.alphaPattern"
              :pattern="alphaPattern"
              :message="'Do not use digits in the ' + head.text"
            />
          </DxSimpleItem>
          <!-- Number Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter((i) => i.type === 'number')"
            :key="'form-field-number-' + index"
            :data-field="head.value"
          >
            <DxLabel :text="head.text" />
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
            <DxPatternRule
              v-if="head.maxLength === undefined"
              :pattern="numericPattern"
              message="Use numeric only"
            />
            <DxPatternRule
              v-else
              :pattern="/^[0-9]{0,13}$/"
              :message="'Use numeric only, max length is ' + head.maxLength"
            />
          </DxSimpleItem>
          <!-- Email Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter((i) => i.type === 'email')"
            :key="'form-field-email-' + index"
            :data-field="head.value"
          >
            <DxLabel :text="head.text" />
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
            <DxPatternRule
              :pattern="emailPattern"
              message="Please use email format"
            />
          </DxSimpleItem>
          <!-- Textarea Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter(
              (i) => i.type === 'textarea'
            )"
            :key="'form-field-textarea-' + index"
            :data-field="head.value"
            editor-type="dxTextArea"
          >
            <DxLabel :text="head.text" />
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
          </DxSimpleItem>
          <!-- Checkbox True False Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter(
              (i) => i.type === 'checkbox'
            )"
            :key="'form-field-checkbox-' + index"
            :items="['true', 'false']"
            :data-field="head.value || false"
            editor-type="dxCheckBox"
          >
            <DxLabel :text="head.value" />
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
          </DxSimpleItem>
          <!-- File Fields -->
          <DxSimpleItem
            v-for="(head, index) in headers.filter((i) => i.type === 'file')"
            :key="'form-field-file-' + index"
            :data-field="head.value"
            style="padding-top: 5px"
          >
            <template #default="{ data }">
              <div>
                <input
                  :id="'form-field-file-' + index"
                  type="file"
                  @change="fileOnChange($event)"
                  accept="image/x-png,image/gif,image/jpg,image/jpeg"
                  hidden
                />
                <div class="dropdown">
                  <div @click="dropdownFile" class="dropbtn">Choose File</div>
                  <div id="myDropdown" class="dropdown-content">
                    <a
                      href="javascript:void(0)"
                      @click="
                        openDialogUploadFile(data, 'form-field-file-' + index)
                      "
                      >Upload</a
                    >
                    <a href="javascript:void(0)">From Camera</a>
                  </div>
                </div>
                <span style="border: 2px solid black; padding: 5px 10px">{{
                  data.value
                }}</span>
              </div>
            </template>
            <DxRequiredRule
              v-if="head.required || false"
              :message="head.value + ' is required'"
            />
          </DxSimpleItem>
        </DxForm>
      </DxEditing>

      <!-- Templates -->
      <template #numeric>
        <DxTextBox :max-length="10" />
      </template>

      <!-- Action -->
      <template #sensorConfig="{}">
        <center>
          <el-dropdown trigger="click" class="mr-2">
            <el-button
              title="View"
              icon="el-icon-view"
              type="primary"
              size="mini"
              circle
            >
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="showData(props.row)"
                >View Details</el-dropdown-item
              >
              <el-dropdown-item>Delete</el-dropdown-item>
              <el-dropdown-item>Force Value</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <el-button
            title="Edit"
            icon="el-icon-edit-outline"
            type="primary"
            size="mini"
            @click="editRow(props.row)"
            circle
          >
          </el-button>
        </center>
      </template>

      <template #customFormulaListAction="{ data }">
        <center>
          <el-button
            title="Edit"
            icon="el-icon-edit-outline"
            type="primary"
            size="mini"
            @click="$emit('edit-row', data.row)"
            circle
          >
          </el-button>
          <el-button
            title="Remove"
            icon="el-icon-delete"
            type="danger"
            size="mini"
            @click="$emit('remove-row', data.row)"
            circle
          >
          </el-button>
        </center>
      </template>

      <template #fileAction="{ data }">
        <div v-if="data.data.FileExtention">
          <p
            class="cursor-pointer"
            v-if="
              imageExtension.includes(data.data.FileExtention) &&
              imageDialog.length
            "
            @click="imageDialog[data.rowIndex].check = true"
          >
            <AppIcon name="file-image" size="l" />
            <el-dialog
              width="30%"
              :visible.sync="imageDialog[data.rowIndex].check"
              append-to-body
            >
              <div class="flex justify-center">
                <img
                  class="inline-block"
                  :src="$baseURL + data.data.FilePreview"
                />
              </div>
              <div slot="footer" class="dialog-footer">
                <el-button @click="imageDialog[data.rowIndex].check = false"
                  >Close</el-button
                >
              </div>
            </el-dialog>
          </p>
          <a
            v-else-if="fileExtension.includes(data.data.FileExtention)"
            :href="$baseURL + data.data.FilePreview"
            target="_blank"
            rel="noopener"
          >
            <AppIcon
              :name="`file-${data.data.FileExtention.split('.').join('')}`"
              size="l"
            />
          </a>
          <a
            v-else-if="!fileExtension.includes(data.data.FileExtention)"
            :href="$baseURL + data.value"
            target="_blank"
            rel="noopener"
          >
            <AppIcon :name="file" size="l" />
          </a>
        </div>
        <p v-else>No items to display.</p>
      </template>

      <template #documentAction="{ data }">
        <div class="flex items-center justify-center p-4" v-if="data.value">
          <a :href="data.value" target="_blank" rel="noopener">
            <i class="text-4xl el-icon-document"> </i>
          </a>
        </div>
      </template>
      <template #dateData="{ data }">
        <center>
          {{
            $moment(data.value, "YYYY-MM-DDTHH:mm:ss+HH:mm").format(
              "DD-MM-YYYY HH:mm"
            )
          }}
        </center>
      </template>

      <template #statusAction="{ data }">
        <center>
          <el-button
            v-if="data.value == 'In Progress' || data.value == 'Finish'"
            title="Edit"
            :type="data.value == 'In Progress' ? 'primary' : 'success'"
            size="mini"
          >
            {{ data.value }}
          </el-button>
          <el-button v-else title="Edit" size="mini">
            {{ data.value }}
          </el-button>
        </center>
      </template>

      <template #backupRestore="{ data }">
        <center>
          <el-button
            title="Download"
            icon="el-icon-download"
            type="primary"
            size="mini"
            @click="$emit('download-row', data.row)"
            circle
          >
          </el-button>
          <el-button
            title="Restore"
            icon="el-icon-refresh-left"
            type="primary"
            size="mini"
            @click="$emit('restore-row', data.row)"
            circle
          >
          </el-button>
          <el-button
            title="Remove"
            icon="el-icon-delete"
            type="danger"
            size="mini"
            @click="$emit('remove-row', data.row)"
            circle
            v-if="options.allowDeleting == null || options.allowDeleting"
          >
          </el-button>
        </center>
      </template>

      <template #commonAction="{ data }">
        <center>
          <el-button
            title="Edit"
            icon="el-icon-edit-outline"
            type="primary"
            size="mini"
            @click="$emit('edit-row', data.row)"
            circle
          >
          </el-button>
          <el-button
            title="Remove"
            icon="el-icon-delete"
            type="danger"
            size="mini"
            @click="$emit('remove-row', data.row)"
            circle
            v-if="options.allowDeleting == null || options.allowDeleting"
          >
          </el-button>
        </center>
      </template>

      <template #displayDay="{ data }">
        <center>
          {{ dayOfWeek[data.value] }}
        </center>
      </template>
    </DxDataGrid>

    <!-- Dialog delete -->
    <el-dialog :visible.sync="dialogVisible" width="30%"></el-dialog>
  </div>
</template>
<script>
/* eslint-disable */
import AppIcon from '@/components/AppIcon'
import {
  DxForm,
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxEditing,
  DxSelection,
  DxFilterRow,
  DxExport,
  DxColumnFixing
} from 'devextreme-vue/data-grid'
// import { DxDropDownButton } from 'devextreme-vue';
import {
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
  DxPatternRule
} from 'devextreme-vue/form'
// import confirm from 'devextreme/ui/dialog'
import notify from 'devextreme/ui/notify'
import { DxTextBox } from 'devextreme-vue/text-box'
import { exportDataGrid } from 'devextreme/excel_exporter'
import ExcelJS from 'exceljs'
import saveAs from 'file-saver'
import appsettings from '@/config.json'

export default {
  props: {
    tableId: {
      type: String,
      default: () => 'Id'
    },
    pageTitle: {
      type: String,
      default: () => ''
    },
    headers: {
      type: Array
    },
    items: {
      type: Array
    },
    exportToExcel: {
      type: Boolean
    },
    options: {
      type: Object,
      default: function () {
        return {
          allowAdding: false,
          allowEditing: false,
          allowDeleting: false,
          allowExporting: false,
          allowSorting: true,
          allowMultipleSelect: true,
          autoWidth: false
        }
      }
    },
    customizationPage: {
      type: String
    },
    printable: {
      type: Boolean,
      default: () => false
    },
    selectedIds: {
      type: Array
    }
  },
  components: {
    // AppIcon,
    AppIcon: () => import('@/components/AppIcon'),
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxPager,
    DxEditing,
    DxForm,
    DxSelection,
    DxFilterRow,
    DxExport,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxPatternRule,
    DxTextBox,
    DxColumnFixing
  },
  data: () => {
    return {
      imageDialog: [],
      alphaPattern: /^[^0-9]+$/, // eslint-disable-line
      numericPattern: /^(0|[1-9][0-9]*)$/, // eslint-disable-line
      emailPattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
      dialogVisible: false,
      selectedRowKeys: [],
      tagColors: ['success', 'info', 'danger', 'warning'],
      dayOfWeek: [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]
    }
  },
  methods: {
    log(eLog) {
      console.log('logging', eLog)
    },
    validateForm(e) {
      e.component.validate()
    },
    customEdit(page, id, mode = 'edit') {
      this.$router.push({ path: page, query: { mode: mode, id: id } })
    },
    onSelectionChanged({ selectedRowsData }) {
      this.$emit('selection-changed', selectedRowsData)
    },
    getRandomInt(min = 1, max = 10, withDecimal = false) {
      return withDecimal
        ? (Math.random() * (max - min) + min).toFixed(2)
        : Math.floor(Math.random() * (max - min) + min)
    },
    exportToExcelNow() {
      const context = this
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet(this.pageTitle)

      exportDataGrid({
        component: context.datagridInstance,
        worksheet: worksheet,
        autoFilterEnabled: true
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: 'application/octet-stream' }),
            `${this.pageTitle}.xlsx`
          )
        })
      })

      this.$emit('exportToExcelFinish', true)
    }
  },
  computed: {
    datagridInstance: function () {
      return this.$refs.simpleTable.instance
    },
    imageExtension() {
      const data = ['png', 'jpg', 'jpeg', 'ico', 'webp', 'svg']
      return data
    },
    fileExtension() {
      const type = ['pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx', 'txt']
      return type
    }
  },
  watch: {
    exportToExcel(val) {
      if (val) {
        this.exportToExcelNow()
      }
    },
    selectedIds(val) {
      if (val) {
        this.selectedRowKeys = val
      }
    },
    items(val) {
      if (val.length) {
        let isImage = val.findIndex((x) =>
          this.imageExtension.includes(x.FileExtention)
        )
        if (isImage > -1) {
          val.forEach(() => {
            this.imageDialog.push({ check: false })
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#data-grid-demo {
  min-height: 700px;
}

.simple-link {
  color: #478ed1;
  text-decoration: underline;
  cursor: pointer;
  margin: 0px 3px;
}
.simple-link.labeled-icon {
  text-decoration: none;
}
.simple-link.icon {
  font-size: 28px;
  text-decoration: none;
}
.simple-link.disabled {
  color: #ccc;
  cursor: unset;
}

// Custom Dropdown

.dropbtn {
  background-color: white;
  color: #333333;
  border: 1px solid #dddddd;
  border-radius: 3px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #f1f1f1;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  margin-top: 3px;
  border: 1px solid #dddddd;
  border-radius: 3px;
  min-width: 100px;
  overflow: auto;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 5px 10px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}
</style>
