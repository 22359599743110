const List = () => import("@/pages/master/tag/List")
const Form = () => import("@/pages/master/tag/Form")
// import List from '@/pages/master/tag/List'
// import Form from '@/pages/master/tag/Form'
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/tag",
    name: "tag-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tag" },
        { title: "List", active: true }
      ],
      title: "Tag List",
      module: "Tag",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/tag/create",
    name: "tag-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tag" },
        { title: "Create", active: true }
      ],
      title: "Tag Create",
      module: "Tag",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/tag/view/:id",
    name: "tag-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tag" },
        { title: "View", active: true }
      ],
      title: "Tag View",
      module: "Tag",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/tag/edit/:id",
    name: "tag-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Tag" },
        { title: "Edit", active: true }
      ],
      title: "Tag Edit",
      module: "Tag",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
