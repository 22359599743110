<template>
  <div
    id="header"
    class="xl:h-16 xl:flex-row xl:justify-between xl:items-center xl:flex flex flex-col"
  >
    <div class="flex justify-between items-center">
      <div v-if="!isCustomer">
        <span class="text-sm lg:text-xl font-bold text-red-600 mr-2"
          >TENANT</span
        >
        <span class="text-sm lg:text-xl font-bold text-blue-600 mr-2"
          >PORTAL</span
        >
      </div>
      <div class="flex" v-if="isCustomer">
        <img
          :src="logoSource"
          :alt="`Logo ${user.CompanyNames[0]}`"
          style="max-height: 50px"
          v-if="logoSource"
        />
        <div class="m-4">
          <span class="text-sm lg:text-xl font-bold text-red-600 mr-2"
            >TENANT</span
          >
          <span class="text-sm lg:text-xl font-bold text-blue-600 mr-2"
            >PORTAL</span
          >
        </div>
      </div>
      <div class="flex xl:hidden">
        <button
          @click="toggle"
          type="button"
          class="px-2 text-gray-500 hover:text-blue-700 focus:outline-none focus:text-blue-500"
        >
          <svg
            class="h-6 w-6 fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              v-if="isOpen"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
            />
            <path
              v-if="!isOpen"
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            />
          </svg>
        </button>
      </div>
    </div>

    <div
      class="xl:flex xl:items-center bg-gray-100 p-2 border rounded-lg xl:bg-white xl:p-0 xl:border-none xl:rounded-none"
      :class="{ hidden: !isOpen, block: isOpen }"
    >
      <div
        class="flex flex-col pt-2 lg:pt-0 lg:flex lg:flex-row lg:items-center"
        :key="index"
        v-for="(rule, index) in menu"
      >
        <router-link
          tag="a"
          class="menu-items cursor-pointer text-md font-bold text-gray-500 mr-8 uppercase"
          role="menuitem"
          :to="rule.Path"
          exact
        >
          {{ rule.Text }}
        </router-link>
      </div>

      <div class="flex mt-2 lg:mt-0">
        <!-- DCE dropdown -->
        <div class="lg:ml-4" v-if="!isStaff">
          <el-dropdown @command="openUrl">
            <el-tooltip
              class="item"
              effect="dark"
              content="DCE"
              placement="top-start"
            >
              <div
                class="w-10 h-10 p-1 bg-white border rounded-full hover:bg-red-600 flex justify-center items-center text-red-600 hover:text-white"
              >
                <app-icon :name="'ITE'" style="height: 1.5em" fill></app-icon>
              </div>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="(item, index) in dce" :key="index" :command="item.Base_Url"
                >{{ item.Server_name }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!-- End of ITE dropdown -->
        <!-- DCO dropdown -->

        <div class="ml-4">
          <el-dropdown @command="openUrl">
            <el-tooltip
              class="item"
              effect="dark"
              content="ITA"
              placement="top-start"
            >
              <div
                class="w-10 h-10 p-1 bg-white border rounded-full hover:bg-tm-blue flex justify-center items-center text-tm-blue hover:text-white"
              >
                <app-icon :name="'DCO'" style="height: 1.5em" fill></app-icon>
              </div>
            </el-tooltip>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="config.KVDC.DCO"
                >KVDC</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="allowNotification()">
          <el-tooltip
            class="item"
            effect="dark"
            content="Notification"
            placement="top-start"
          >
            <div
              class="ml-4 border bg-white h-10 w-10 relative inline-flex rounded-full shadow-sm cursor-pointer hover:bg-blue-500 hover:text-white"
              @click="onAlarmSummaryClick(true)"
            >
              <div class="inline-flex px-2 items-center">
                <i class="el-icon-bell" style="font-size: 22px" />
              </div>
              <span
                class="flex absolute h-auto top-0 right-0 -mt-2 -mr-3 content-center justify-center"
              >
                <span
                  class="animate-ping absolute inline-flex h-full w-full rounded-full bg-pink-400 opacity-75"
                ></span>
                <span
                  class="rounded-full h-full w-full bg-pink-500 text-white text-xs p-1"
                  >{{ totalAlarms }}</span
                >
              </span>
            </div>
          </el-tooltip>

          <AlarmPopup
            title="Alarm Notification List"
            :show-dialog="displayAlarmPopup"
            @onClose="onAlarmSummaryClick(false)"
            @refreshAlarm="getAlarmsNotification"
          />
        </div>
        <div>
          <el-dropdown>
            <el-tooltip
              class="item"
              effect="dark"
              content="User Account"
              placement="top-start"
            >
              <div
                class="ml-4 border bg-white h-10 w-10 relative inline-flex rounded-full shadow-sm cursor-pointer hover:bg-blue-500 hover:text-white"
              >
                <div class="inline-flex px-2 items-center">
                  <i class="el-icon-s-custom" style="font-size: 22px" />
                </div>
              </div>
            </el-tooltip>

            <el-dropdown-menu slot="dropdown" class="p-0">
              <el-dropdown-item class="p-0">
                <div
                  class="flex space-x-0 space-y-2 lg:space-x-2 lg:space-y-0 p-2 px-4 leading-4"
                  @click="viewProfile"
                >
                  <i
                    class="el-icon-s-custom"
                    :class="userCompany ? 'mt-5' : 'mt-2'"
                    style="font-size: 22px"
                  />
                  <div class="">
                    <div class="font-bold">{{ user.Fullname }}</div>
                    <div class="text-xs mb-4" v-if="userCompany">
                      From <span class="font-bold">{{ userCompany }}</span>
                    </div>
                    <div class="text-xs">{{ user.Email }}</div>
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item class="p-0">
                <div class="bg-blue-600 text-white p-2 px-4" @click="logout">
                  <app-icon
                    name="logout"
                    class="mr-4"
                    style="height: 1.5em"
                  ></app-icon>
                  Logout
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import AppIcon from '@/components/AppIcon'
import config from "@/config.json"
import { mixin as VueTimers } from "vue-timers"
import formattedValue from "@/helpers/formattedValue"
import AlarmPopup from "@/components/Alarm/PopupDetails"

export default {
  components: {
    AppIcon: () => import("@/components/AppIcon"),
    AlarmPopup
  },
  mixins: [VueTimers],
  timers: {
    getAlarmsNotification: {
      autostart: false,
      repeat: true,
      immediate: true,
      time: 300000
    }
  },
  data() {
    return {
      isOpen: false,
      logoSource: "",
      alarms: {},
      totalAlarms: 0,
      config: config,
			dce: [],
      alarmData: {
        startDate: "",
        endDate: "",
        reportType: "Alarm Report",
        locationReport: ["KVDC"],
        locationReportNode: ["KVDC"],
        dataForm: "daily",
        customer: "",
        ListFormula: []
      },
      displayAlarmPopup: false
    }
  },
  async mounted() {
    this.getLogoSource()
		this.fetchDce()
    if (this.allowNotification()) {
      // this.getAlarmsNotification()
      this.timers.getAlarmsNotification.autostart = true
      this.$timer.restart("getAlarmsNotification")
    }

    this.getSensorsByCustomers()
  },
  computed: {
    menu() {
      var _this = this

      return this.$store.state.auth.menu
        .filter((x) => !x.ParentId)
        .map(function (item) {
          if (item.PermissionName === "Administrator" && _this.isStaff) {
            item.Path = "/profile"
          }

          return item
        })
        .sort(function (a, b) {
          return a.OrderSort - b.OrderSort
        })
    },
    isCustomer() {
      return this.$store.state.auth.isCustomer
    },
    isStaff() {
      return this.$store.state.auth.isStaff
    },
    logo() {
      return this.$store.state.auth.userData.CustomerLogo
    },
    user() {
      return this.$store.state.auth.userData
    },
    userCompany() {
      return this.$store.state.auth.userData.CompanyNames.length > 0
        ? this.$store.state.auth.userData.CompanyNames[0]
        : ""
    },
    userCustomer() {
      return this.$store.state.auth.userData.CustomerName.length > 0
        ? this.$store.state.auth.userData.CustomerName[0]
        : ""
    },
    currentRouteName() {
      return this.$route.name
    }
  },
  methods: {
		async fetchDce() {
			try {
				const { data: res } = await this.$axios.get(
          this.$appInfo.api.configExtSystem.getAll
        )
				this.dce = res.filter((data) => data.Status == 'dce')
			} catch (error) {
				console.error(error)				
			}
		},
    toggle() {
      this.isOpen = !this.isOpen
    },
    readableDate(stringDate) {
      let newDate = new Date(stringDate)
      return `${newDate.toDateString()} ${newDate.toTimeString()}`
    },
    openUrl(url) {
      window.open(url, "_blank")
    },
    allowNotification() {
      return (
        this.user.RolesName.includes("TM Administrator") ||
        this.user.RolesName.includes("TM Staff")
      )
    },
    openNotification(url) {
      // set alarm data
      this.alarmData.startDate = formattedValue.date(
        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
      )
      this.alarmData.endDate = formattedValue.date(new Date())
      this.alarmData.customer = this.userCustomer

      this.$router.push({ name: "report", query: this.alarmData })
    },
    viewProfile(url) {
      this.$router.push({ name: "profileView" })
    },
    setAllFormulaIds(data) {
      var _this = this

      // set disability
      return data.map(function (item) {
        // populate formula
        if (item.IsFormula) {
          _this.alarmData.ListFormula.push(item.Dce_id)
        }

        // find on children data
        if (item.children.length) {
          _this.setAllFormulaIds(item.children)
        }
        return item
      })
    },
    onAlarmSummaryClick(val) {
      // manipulate display alarm state
      if (val) {
        this.displayAlarmPopup = false
      }

      this.displayAlarmPopup = val
    },

    async logout() {
      this.$confirm("Are you sure you want to logout?", "Confirm Logout", {
        confirmButtonText: "Logout",
        cancelButtonText: "Cancel",
        type: "success"
      })
        .then(async () => {
          this.$router.push({ name: "logout" })
        })
        .catch(() => {})
    },
    async getLogoSource() {
      try {
        if (!this.user.CustomerName.length) {
          return
        }

        const res = await this.$axios.get(
          this.$appInfo.api.customer.getMiniLogohotoBase64 +
            `?customer_name=` +
            this.user.CustomerName[0]
        )
        this.logoSource = res.data
      } catch (e) {
        console.log("failed to fetch logo", e)
      }
    },
    async getAlarmsNotification() {
      try {
        const res = await this.$axios.get(
          this.$appInfo.api.alarms.countCriticalAlarm
        )

        var _this = this
        this.totalAlarms = res.data
      } catch (e) {
        console.log("failed to fetch alarms notification", e)
      }
    },
    async getSensorsByCustomers(CustomerDcoId) {
      try {
        this.loading = true
        const res = await this.$axios.get(
          //this.$appInfo.api.reportTree.getCustTreeSensorFiltered + '?CustomerDcoId=' + CustomerDcoId + '&Filter=ENERGY'
          this.$appInfo.api.treeGroupDevice
            .getCustTreeGroupDeviceLevelWithFormula +
            "?CustomerDcoId=" +
            this.userCustomer +
            "&Tags=" +
            this.alarmData.reportType
        )

        // reset & set all formula ids
        this.alarmData.ListFormula = []
        this.setAllFormulaIds(res.data)

        this.loading = false
      } catch (e) {
        this.loading = false
        console.log(e)
      }
    }
  }
}
</script>

<style>
.menu-items.router-link-active {
  @apply text-blue-600 !important;
}
.menu-items {
  @apply text-gray-500 !important;
}
</style>
