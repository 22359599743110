<template>
  <div class="bg-white flex flex-col p-4 rounded" v-loading="fullscreenLoading">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <h3>{{ title }}</h3>
    </div>
    <hr style="margin: 0px 0px 10px 0px" />
    <SimpleTable
      :headers="headers"
      :items="items"
      :options="options"
      @edit-row="edit"
      @remove-row="remove"
    />
  </div>
</template>

<script>
import SimpleTable from "@/components/dx/SimpleTable"
import formService from "@/mixins/FormService"
import formHelper from "./form-helper"

let service

export default {
  components: {
    SimpleTable
  },

  mounted() {
    this.fetchData()
    service = new formService(this, formHelper)
  },
  data() {
    return {
      title: "Config DCE & DCO - List",
      fullscreenLoading: false,
      headers: [
        { type: "string", text: "Base URL", value: "Base_Url" },
        { type: "string", text: "Username", value: "Username" },
        { type: "string", text: "Type", value: "Status" },
        {
          type: "string",
          text: "Action",
          value: "Id",
          cellTemplate: "commonAction"
        }
      ],
      items: [],
      RecordsOnDuty: 0,
      options: {
        allowToAdd: false,
        allowToRefetch: true,
        allowDeleting: false
      }
    }
  },
  methods: {
    edit(row) {
      var id = row.data.Id
      this.$router.push({
        name: `config-dce-edit`,
        params: { id: id }
      })
    },
    async remove(row) {
      this.$confirm(
        "This will permanently delete this record. Continue?",
        "Are you sure",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(async () => {
          try {
            var id = row.data.Id
            await service.remove(id)

            this.fetchData()

            this.$message({
              type: "success",
              message: "Delete completed"
            })
          } catch (e) {
            this.$message.error(e)
            this.fullscreenLoading = false
            console.log(e)
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          })
        })
    },

    async fetchData() {
      try {
        this.fullscreenLoading = true
        const res = await this.$axios.get(this.$appInfo.api.configDce.getAll)
        this.items = res.data
        this.fullscreenLoading = false
      } catch (e) {
        this.fullscreenLoading = false
        console.log(e)
      }
    }
  }
}
</script>
