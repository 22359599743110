const Main = () => import("@/pages/report/Main")
const Printable = () => import("@/pages/report/Printable")
// import Main from '@/pages/report/Main'
// import Printable from '@/pages/report/Printable'
import layout from "@/layout/portal"
import defaultLayout from "@/layout/default"

const data = [
  {
    path: "/report",
    name: "report",
    components: {
      content: Main,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Custom Report" },
        { title: "List", active: true }
      ],
      title: "Custom Report List",
      module: "Custom Report",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/report/preview",
    name: "preview-report",
    components: {
      content: Printable,
      layout: defaultLayout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Print Report", active: true }
      ],
      title: "Print Report",
      module: "Print Report",
      showLink: false,
      requiresAuth: false
    }
  }
]
export default data
