import layout from '@/layout/Master'

const data = [
  {
    path: '/config/external-system-config',
    name: 'ext-system-config-list',
    components: {
      content: () => import('@/pages/config/external-system-config/List.vue'),
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'External System Config' },
        { title: 'List', active: true }
      ],
      title: 'External System Config | Tenant Portal',
      module: 'External System Config',
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: '/config/external-system-config/edit/:id',
    name: 'ext-system-config-edit',
    components: {
      content: () => import('@/pages/config/external-system-config/Form.vue'),
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: 'External System Config' },
        { title: 'Edit', active: true }
      ],
      title: 'External System Config | Tenant Portal',
      module: 'External System Config',
      showLink: false,
      requiresAuth: true
    }
  },
]
export default data
