const List = () => import("@/pages/formula/List")
const ConfigInit = () => import("@/pages/formula/ConfigInit")
const ConfigForm = () => import("@/pages/formula/ConfigForm")
// import List from '@/pages/formula/List'
// import ConfigInit from '@/pages/formula/ConfigInit'
// import ConfigForm from '@/pages/formula/ConfigForm'
import layout from "@/layout/portal"

const data = [
  {
    path: "/formula",
    name: "formula-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Custom Formula" },
        { title: "List", active: true }
      ],
      title: "Custom Formula List",
      module: "Custom Formula",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/formula/init",
    name: "formula-init",
    components: {
      content: ConfigInit,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Custom Formula" },
        { title: "Create", active: true }
      ],
      title: "Custom Formula Initialization",
      module: "Custom Formula",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/formula/config",
    name: "formula-config",
    components: {
      content: ConfigForm,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Custom Formula" },
        { title: "Create", active: true }
      ],
      title: "Custom Formula Configuration",
      module: "Custom Formula",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
