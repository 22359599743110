const List = () => import("@/pages/master/docs/List")
const Form = () => import("@/pages/master/docs/Form")
const FormUpload = () => import("@/pages/master/docs/Form")
// import List from '@/pages/master/docs/List'
// import Form from '@/pages/master/docs/Form'
// import FormUpload from '@/pages/master/docs/Upload'
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/docs",
    name: "docs-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Docs" },
        { title: "List", active: true }
      ],
      title: "Docs List",
      module: "Document",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/docs/create",
    name: "docs-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Docs" },
        { title: "Create", active: true }
      ],
      title: "Docs Create",
      module: "Document",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/docs/upload/:id",
    name: "docs-upload",
    components: {
      content: FormUpload,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Docs" },
        { title: "Create", active: true }
      ],
      title: "Upload Docs File",
      module: "Document",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/user/view/:id",
    name: "docs-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Docs" },
        { title: "View", active: true }
      ],
      title: "Docs View",
      module: "Document",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/docs/edit/:id",
    name: "docs-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Docs" },
        { title: "Edit", active: true }
      ],
      title: "Docs Edit",
      module: "Document",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
