import Vue from "vue"
import Router from "vue-router"

import Login from "@/pages/Login" // Reset Password
const ResetPassword = () => import("@/pages/ResetPassword")
// import ResetPassword from '@/pages/ResetPassword'
const Register = () => import("@/pages/Register")
// import Register from '@/pages/Register'
import Unauthorized from "@/pages/error/404"
// import Settings from '@/pages/Settings'
// import afterLoginLayout from '@/layout/login'
import defaultLayout from "@/layout/default"
// import portalLayout from '@/layout/portal'
import store from "@/store"
import nProgress from "nprogress"

// modular routes
import MasterUser from "./routers/user"
import MasterDocs from "./routers/docs"
import MasterTickets from "./routers/tickets"
import MasterTicketsAdmin from "./routers/ticketsAdmin"
import MasterPermission from "./routers/permission"
import MasterRole from "./routers/role"
import CustomFormula from "./routers/formula"
import CustomReport from "./routers/report"
import Dashboard from "./routers/dashboard"
import FormulaConstanta from "./routers/constanta"
import MasterTag from "./routers/tag"
import MasterWidget from "./routers/widget"
import MasterChart from "./routers/chart"
import MasterCustomerLogo from "./routers/customerLogo"
import MasterScheduledReport from "./routers/scheduledReport"
import AuditLog from "./routers/auditLog"
import SyncData from "./routers/sync"
import Version from "./routers/version"
import MasterConfigDce from "./routers/config-dce"
import CustomerPowerAndGroup from "./routers/customerPowerAndGroup"
import ExternalSystemConfig from './routers/externalSystemConfig'

Vue.use(Router)

const router = new Router({
  mode: "history",
  routes: [
    // =============================================================================
    // Application Routes
    // =============================================================================
    ...Dashboard,
    ...MasterUser,
    ...MasterDocs,
    ...MasterTickets,
    ...MasterTicketsAdmin,
    ...MasterPermission,
    ...MasterRole,
    ...CustomFormula,
    ...CustomReport,
    ...FormulaConstanta,
    ...MasterTag,
    ...MasterWidget,
    ...MasterChart,
    ...MasterCustomerLogo,
    ...MasterScheduledReport,
    ...AuditLog,
    ...SyncData,
    ...Version,
    ...MasterConfigDce,
    ...CustomerPowerAndGroup,
		...ExternalSystemConfig,
    {
      path: "/login",
      name: "login",
      components: {
        content: Login,
        layout: defaultLayout
      },
      meta: {
        title: "Login | Tenant Portal",
        showLink: false,
        guest: true,
        requiresAuth: false
      }
    },
    {
      path: "/forgot-password",
      name: "Reset Password",
      meta: { title: "Reset Password | Tenant Portal ", guest: true },
      components: {
        layout: defaultLayout,
        content: ResetPassword
      }
    },
    {
      path: "/logout",
      name: "logout",
      component: {
        beforeRouteEnter(to, from, next) {
          const destination = {
            path: "/login",
            query: from.query,
            params: from.params
          }

          store.dispatch("deleteToken")
          next(destination)
        }
      },
      meta: {
        title: "Logout",
        showLink: false,
        requiresAuth: false
      }
    },
    {
      path: "/register",
      name: "Register",
      components: {
        content: Register,
        layout: defaultLayout
      },
      meta: {
        title: "Register | Tenant Portal",
        showLink: false
        // guest: true
      }
    },
    {
      path: "/404",
      name: "Unauthorized",
      components: {
        content: Unauthorized,
        layout: defaultLayout
      },
      meta: {
        title: "Unauthorized access! | Tenant Portal",
        showLink: false
      }
    },
    {
      path: "/",
      redirect: "/home",
      meta: {
        showLink: false
      }
    },
    {
      path: "*",
      redirect: "/home",
      meta: {
        showLink: false
      }
    }
  ]
})

// un-comment this line for activating middleware
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    nProgress.start()
    // calling validate token
    await store.dispatch("validateToken")

    // get user profile
    if (!store.getters.userData.hasOwnProperty("ListPermission")) {
      await store.dispatch("getUserProfile")
    }

    // set current route
    store.commit("setCurrentRoute", to)

    if (!store.getters.validToken) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      })
    } else if (!store.getters.authorized) {
      next({
        path: "/404",
        query: { nextUrl: "/" }
      })
    } else next()
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.loggedIn) {
      next({
        path: "/",
        params: { nextUrl: to.fullPath }
      })
    } else next()
  } else {
    next()
  }
})

router.afterEach(() => {
  nProgress.done()
})

// router.afterEach(async (to, from) => {
// 	if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // calling user profile
//     await store.dispatch('getUserProfile')

//     if (!store.getters.authorized) {
//       next({
//         path: '/404',
//         query: { nextUrl: '/' }
//       })
//     }
//   }
// })

export default router
