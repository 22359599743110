import axios from "axios"

class serviceForm {
  vue
  helper
  id

  constructor(vue, helper, id = null) {
    this.vue = vue
    this.helper = helper
    this.id = id
  }

  get initialFormData() {
    return async () => {
      let formData

      var id = this.vue.$route.params.id
      if (this.id) {
        id = this.id
      }

      if (id) {
        const res = await axios.get(`${this.vue.apiGetById}?id=${id}`)
        formData = res.data
      } else {
        formData = Object.assign({}, this.helper.getEmptyData())
      }

      return formData
    }
  }

  async submit(formData, redirect = true) {
    try {
      if (this.vue.$route.params.id || this.id) {
        await axios
          .put(`${this.vue.apiPut}`, formData)
          .then(() => {
            if (redirect) {
              this.vue.$router.go(-1)
            }
          })
          .catch((error) => {
            throw error.response
          })
      } else {
        await axios
          .post(`${this.vue.apiPost}`, formData)
          .then(() => {
            this.vue.$router.go(-1)
          })
          .catch((error) => {
            throw error.response
          })
      }
    } catch (e) {
      this.extractErrMessage(e)
    }
  }

  async customSubmit(formData) {
    try {
      if (this.vue.$route.params.id) {
        await axios
          .put(`${this.vue.apiPut}`, formData)
          .then(() => {
            this.vue.$router.push({
              name: "docs-upload",
              query: { id: this.vue.$route.params.id }
            })
          })
          .catch((error) => {
            throw error.response
          })
      } else {
        await axios
          .post(`${this.vue.apiPost}`, formData)
          .then((response) => {
            this.vue.$router.push({
              name: "docs-upload",
              params: { id: response.data.ListIdUpload }
            })
          })
          .catch((error) => {
            throw error.response
          })
      }
    } catch (e) {
      this.extractErrMessage(e)
    }
  }

  async remove(id) {
    try {
      await axios.delete(`${this.vue.apiRemove}?id=${id}`).catch((error) => {
        throw error.response
      })
    } catch (e) {
      this.extractErrMessage(e)
    }
  }

  extractErrMessage(response) {
    // display on console log
    console.log(response)

    if (response.status == 400) {
      if (Object.prototype.hasOwnProperty.call(response.data, "message")) {
        throw response.data.message
      } else if (
        Object.prototype.hasOwnProperty.call(response.data.Object, "title")
      ) {
        throw response.data.title
      }
    } else {
      throw response.statusText
    }
  }
}

export default serviceForm
