const List = () => import("@/pages/config/chart/List")
const Form = () => import("@/pages/config/chart/Form")
// import List from '@/pages/config/chart/List'
// import Form from '@/pages/config/chart/Form'
import layout from "@/layout/Master"

const data = [
  {
    path: "/config/chart",
    name: "chart-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Chart" },
        { title: "List", active: true }
      ],
      title: "Chart List",
      module: "Chart",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/chart/create",
    name: "chart-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Chart" },
        { title: "Create", active: true }
      ],
      title: "Chart Create",
      module: "Chart",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/chart/view/:id",
    name: "chart-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Chart" },
        { title: "View", active: true }
      ],
      title: "Chart View",
      module: "Chart",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/chart/edit/:id",
    name: "chart-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Chart" },
        { title: "Edit", active: true }
      ],
      title: "Chart Edit",
      module: "Chart",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
