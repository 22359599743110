var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"background-section overflow-auto min-h-screen",style:({
    backgroundImage: 'url(' + require('@/assets/images/bg.png') + ')'
  })},[_c('router-view'),_c('div',{staticClass:"flex flex-col ... py-4 max-w-screen-large lg:mx-auto lg:px-32 h-screen px-2"},[_vm._m(0),_c('div',{staticClass:"flex-grow w-full px-4 py-2 rounded-lg bg-white shadow-lg"},[_c('Header'),_c('div',{staticClass:"lg:grid lg:grid-cols-6 flex flex-col gap-2"},[(!_vm.sidebarOpen)?_c('el-button',{staticClass:"mb-2",staticStyle:{"height":"40px"},attrs:{"id":"collapse-button","size":"mini","icon":"el-icon-s-unfold"},on:{"click":function($event){return _vm.openSidebar(true)}}},[_vm._v(" Display Sidebar ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sidebarOpen),expression:"sidebarOpen"}],staticClass:"overflow-auto ease-in-out transition-all duration-300",class:_vm.sidebarOpen
              ? 'col-start-1 col-end-3 -translate-x-full'
              : 'col-start-1 col-end-1'},[_c('div',{staticClass:"el-menu-vertical-demo border rounded-lg overflow-auto"},[_c('div',{staticClass:"flex w-full justify-between p-2 pb-0"},[_c('h5',{staticClass:"text-base font-bold text-black p-3"},[_vm._v(" Administration Menu ")]),_c('el-button',{staticClass:"mb-2",staticStyle:{"height":"40px"},attrs:{"id":"collapse-button","size":"mini","icon":"el-icon-close"},on:{"click":function($event){return _vm.openSidebar(false)}}},[_vm._v("Collapse")])],1),_c('el-menu',{staticClass:"el-menu-vertical-demo",attrs:{"default-active":"2"}},[_vm._l((_vm.menu),function(item,index){return [(item.children.length)?_c('el-submenu',{key:index,attrs:{"index":index.toString()}},[_c('template',{slot:"title"},[_c('i',{class:item.Icon}),_c('span',[_vm._v(_vm._s(item.Text))])]),_vm._l((item.children),function(child,childIndex){return [_c('el-menu-item',{key:childIndex,attrs:{"index":`${index}-${childIndex}`},on:{"click":function($event){child.hasOwnProperty('Path')
                          ? _vm.$router.push({ path: child.Path })
                          : ''}}},[_vm._v(" "+_vm._s(child.Text)+" ")])]})],2):_c('el-menu-item',{key:index,attrs:{"index":index.toString()},on:{"click":function($event){item.hasOwnProperty('Path')
                      ? _vm.$router.push({ path: item.Path })
                      : ''}}},[_c('template',{slot:"title"},[_c('i',{class:item.Icon}),_c('span',[_vm._v(_vm._s(item.PermissionName))])])],2)]})],2)],1)]),_c('div',{staticClass:"h-full w-full",class:_vm.sidebarOpen ? 'col-start-3 col-end-7' : 'col-start-1 col-end-7'},[_c('div',{staticClass:"p-4 border rounded-lg h-full w-full"},[_vm._t("default")],2)])],1)],1),_c('div',{staticClass:"flex-none w-full py-8 flex"},[_c('Footer',{staticClass:"flex-1 ..."}),_vm._m(1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-none w-full text-right"},[_c('img',{staticClass:"h-16 inline-block mb-4",attrs:{"src":require("@/assets/images/logo-schneider-electric.png"),"alt":"Schneider Electric Logo","title":"Schneider Electric Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 ... text-right"},[_c('img',{staticClass:"h-12 inline-block",attrs:{"src":"/images/EcoStruxure_logo.png"}})])
}]

export { render, staticRenderFns }