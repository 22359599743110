<template>
  <div
    class="background-section overflow-auto min-h-screen"
    :style="{
      backgroundImage: 'url(' + require('@/assets/images/bg.png') + ')'
    }"
  >
    <router-view />
    <div
      class="flex flex-col ... py-4 max-w-screen-large lg:mx-auto lg:px-32 h-screen px-2"
    >
      <!-- header logo -->
      <div class="flex-none w-full text-right">
        <img
          src="@/assets/images/logo-schneider-electric.png"
          alt="Schneider Electric Logo"
          title="Schneider Electric Logo"
          class="h-16 inline-block mb-4"
        />
      </div>

      <!-- content & menu -->
      <div class="flex-grow w-full px-4 py-2 rounded-lg bg-white shadow-lg">
        <Header />

        <div class="lg:grid lg:grid-cols-6 flex flex-col gap-2">
          <el-button
            id="collapse-button"
            size="mini"
            icon="el-icon-s-unfold"
            style="height: 40px"
            class="mb-2"
            @click="openSidebar(true)"
            v-if="!sidebarOpen"
          >
            Display Sidebar
          </el-button>
          <div
            class="overflow-auto ease-in-out transition-all duration-300"
            :class="
              sidebarOpen
                ? 'col-start-1 col-end-3 -translate-x-full'
                : 'col-start-1 col-end-1'
            "
            v-show="sidebarOpen"
          >
            <div class="el-menu-vertical-demo border rounded-lg overflow-auto">
              <div class="flex w-full justify-between p-2 pb-0">
                <h5 class="text-base font-bold text-black p-3">
                  Administration Menu
                </h5>
                <el-button
                  id="collapse-button"
                  class="mb-2"
                  size="mini"
                  icon="el-icon-close"
                  style="height: 40px"
                  @click="openSidebar(false)"
                  >Collapse</el-button
                >
              </div>
              <el-menu default-active="2" class="el-menu-vertical-demo">
                <template v-for="(item, index) in menu">
                  <el-submenu
                    :key="index"
                    :index="index.toString()"
                    v-if="item.children.length"
                  >
                    <template slot="title">
                      <i :class="item.Icon"></i>
                      <span>{{ item.Text }}</span>
                    </template>
                    <template v-for="(child, childIndex) in item.children">
                      <el-menu-item
                        :key="childIndex"
                        :index="`${index}-${childIndex}`"
                        @click="
                          child.hasOwnProperty('Path')
                            ? $router.push({ path: child.Path })
                            : ''
                        "
                      >
                        {{ child.Text }}
                      </el-menu-item>
                    </template>
                  </el-submenu>

                  <el-menu-item
                    @click="
                      item.hasOwnProperty('Path')
                        ? $router.push({ path: item.Path })
                        : ''
                    "
                    :key="index"
                    :index="index.toString()"
                    v-else
                  >
                    <template slot="title">
                      <i :class="item.Icon"></i>
                      <span>{{ item.PermissionName }}</span>
                    </template>
                  </el-menu-item>
                </template>
              </el-menu>
            </div>
          </div>
          <div
            class="h-full w-full"
            :class="
              sidebarOpen ? 'col-start-3 col-end-7' : 'col-start-1 col-end-7'
            "
          >
            <div class="p-4 border rounded-lg h-full w-full">
              <slot />
            </div>
          </div>
        </div>
      </div>

      <!-- footer logo -->
      <div class="flex-none w-full py-8 flex">
        <Footer class="flex-1 ..." />
        <div class="flex-1 ... text-right">
          <img src="/images/EcoStruxure_logo.png" class="h-12 inline-block" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from "@/components/Portal/Footer"
import Header from "@/components/Portal/Header"
export default {
  name: "portalLayout",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      sidebarOpen: true
    }
  },
  methods: {
    openSidebar(state = true) {
      this.sidebarOpen = state
    }
  },
  computed: {
    menu() {
      var menu = JSON.parse(JSON.stringify(this.$store.state.auth.menu))

      return menu
        .filter((x) => x.ParentId === 6)
        .map(function (item) {
          item.children = menu
            .filter((x) => x.ParentId === item.Id)
            .sort(function (a, b) {
              return a.OrderSort - b.OrderSort
            })
          return item
        })
        .sort(function (a, b) {
          return a.OrderSort - b.OrderSort
        })
    },
    currentRouteName() {
      return this.$route.name
    }
  }
}
</script>
<style lang="scss">
// @apply bg-gradient-to-r from-indigo-500 to-pink-700;

.max-w-screen-large {
  max-width: 1440px;
}
.grid-custom {
  display: grid;
  grid-template-columns: 80px repeat(4, minmax(200px, 1fr)) 80px;
  grid-template-rows: 80px 1fr 1fr 80px;
}
.background-section {
  height: 100%;
  background-repeat: repeat-x;
  background-color: #f1f2f3;
  background-size: cover;
}
</style>
