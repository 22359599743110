const Portal = () => import("@/pages/dashboard/Portal")
const PortalCustomer = () => import("@/pages/dashboard/PortalCustomer")
// import Portal from '@/pages/dashboard/Portal'
// import PortalCustomer from '@/pages/dashboard/PortalCustomer'
import layout from "@/layout/portal"

const data = [
  {
    path: "/",
    name: "dashboard",
    components: {
      content: Portal,
      layout: layout
    },
    meta: {
      breadcrumb: [{ title: "Home", url: "/", active: true }],
      title: "Home",
      module: "Dashboard",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/portal",
    name: "dashboard-customer",
    components: {
      content: PortalCustomer,
      layout: layout
    },
    meta: {
      breadcrumb: [{ title: "Customer Dashboard", url: "/", active: true }],
      title: "Customer Dashboard",
      module: "Tenant Dashboard",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
