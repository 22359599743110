const List = () => import("@/pages/config/version/List")
const Form = () => import("@/pages/config/version/Form")
// import List from '@/pages/config/version/List'
// import Form from '@/pages/config/version/Form'
import layout from "@/layout/Master"
// import layout from '@/layout/portal'

const data = [
  {
    path: "/config/backup-restore",
    name: "backup-restore",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Backup Restore" },
        { title: "List", active: true }
      ],
      title: "Backup Restore",
      module: "Backup Restore",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/config/backup-restore/settings",
    name: "backup-restore-settings",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Backup Restore" },
        { title: "settings", active: true }
      ],
      title: "Settings",
      module: "Backup Restore",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
