const List = () => import("@/pages/master/customer-logo/List")
const Form = () => import("@/pages/master/customer-logo/Form")
// import List from '@/pages/master/customer-logo/List'
// import Form from '@/pages/master/customer-logo/Form'
import layout from "@/layout/Master"

const data = [
  {
    path: "/master/customer-logo",
    name: "customer-logo-list",
    components: {
      content: List,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Customer Logo" },
        { title: "List", active: true }
      ],
      title: "Customer Logo List",
      module: "Customer Logo",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/customer-logo/create",
    name: "customer-logo-create",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Customer Logo" },
        { title: "Create", active: true }
      ],
      title: "Customer Logo Create",
      module: "Customer Logo",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/customer-logo/view/:id",
    name: "customer-logo-view",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Customer Logo" },
        { title: "View", active: true }
      ],
      title: "Customer Logo View",
      module: "Customer Logo",
      showLink: false,
      requiresAuth: true
    }
  },
  {
    path: "/master/customer-logo/edit/:id",
    name: "customer-logo-edit",
    components: {
      content: Form,
      layout: layout
    },
    meta: {
      breadcrumb: [
        { title: "Home", url: "/" },
        { title: "Customer Logo" },
        { title: "Edit", active: true }
      ],
      title: "Customer Logo Edit",
      module: "Customer Logo",
      showLink: false,
      requiresAuth: true
    }
  }
]
export default data
