import { format, date, utc } from "@/helpers/datetime"
import accounting from "@/helpers/accounting.js"

var period = ""

export default {
  datePeriod(value) {
    period = value
  },
  content(number, unit = "", decimal = 2) {
    if (unit === "MYR") {
      return accounting.formatMoney(number, "RM ", decimal)
    } else {
      if (unit && unit !== "NIHIL") {
        return accounting.formatMoney(
          number,
          { symbol: unit, format: "%v %s" },
          decimal
        )
      } else {
        return accounting.formatNumber(number, decimal)
      }
    }
  },
  number(number, decimal = 2) {
    return accounting.formatNumber(number, decimal)
  },
  date(stringDate) {
    if (period === "monthly") {
      return format(stringDate, "YYYY-MM")
    } else if (period === "yearly") {
      return format(stringDate, "YYYY")
    } else {
      return format(stringDate, "YYYY-MM-DD")
    }
  },
  readableDate(stringDate, displayYear = true) {
    if (period === "monthly") {
      return format(stringDate, displayYear ? "MMM YYYY" : "MMM")
    } else if (period === "yearly") {
      return format(stringDate, "YYYY")
    } else {
      return format(stringDate, displayYear ? "DD MMM YYYY" : "DD MMM")
    }
  },
  datetime(stringDate) {
    return format(stringDate, "D MMM YYYY h:mm:ss A")
  }
}
